/*
    to work out font calculations 
    if smaller than base = (base font size - target font size) / target font size 
    if larger than base = (target font size / base font size)
*/


p, ul, ol, dl, blockquote, pre, dialog {
    @apply leading-normal;
}

a {
    transition: all 0.3s;
}

p {
    @apply mb-4 text-base font-proxReg;

    @screen lg {
        @apply text-xl;
    }

    strong {
        @apply font-bold font-proxBold;
    }

    &:last-child {
        @apply mb-0;
    }

    // a {
    //     @apply underline;

    //     &:hover {
    //         @apply no-underline;
    //     }
    // }
}

.lead {
    .device {
        @apply relative;

        &:before {
            content: "";
            @apply absolute bg-red w-20 h-1.5 left-0;
        }

        &.top {
            @apply pt-8;

            &:before { 
                @apply top-0;
            }
        }

        &.bottom {
            @apply pb-8;

            &:before { 
                @apply bottom-0;
            }
        }

        &.white {
             &:before {
                @apply bg-white;
             }
        }
    }

    p {
        @apply text-xl;

        @screen lg { 
            @apply leading-snug;
            font-size: calc(1.3 * theme('fontSize.base-heading-size'));
        }
    }
}

.big {
    p {
        @apply leading-snug;
        font-size: calc(1.1 * theme('fontSize.base-heading-size'));

        @screen lg { 
            font-size: calc(1.6 * theme('fontSize.base-heading-size'));
        }
    }
}

.quote {
    .device {
        @apply relative;

        &:before {
            content: "";
            @apply absolute bg-red w-20 h-1.5 left-1/2;
            transform: translateX(-50%);
        }

        &.top {
            @apply pt-8;

            &:before { 
                @apply top-0;
            }
        }

        &.bottom {
            @apply pb-8;

            &:before { 
                @apply bottom-0;
            }
        }

        &.white {
             &:before {
                @apply bg-white;
             }
        }
    }
    p {
        @apply text-2xl leading-snug;

        @screen lg { 
            font-size: calc(2 * theme('fontSize.base-heading-size'));
        }
    }
}

span.caption {
    @apply font-proxBold uppercase leading-snug text-sm tracking-wider text-medGrey block;

    @screen lg {  
        @apply text-lg;
    }
}


.h1, .wysiwyg-block h1, .h2, .wysiwyg-block h2, .h3, .wysiwyg-block h3, .h4, .wysiwyg-block h4, .h5, .wysiwyg-block h5, .h6, .wysiwyg-block h6 {
    
}

.h1, h1 {
    font-size: calc(1.6 * theme('fontSize.base-heading-size'));
    @apply leading-tight font-semibold font-proxSemi; 

    @screen lg { 
        font-size: calc(3.6 * theme('fontSize.base-heading-size'));
    }
}

.h2, h2 {
    font-size: calc(1.5 * theme('fontSize.base-heading-size'));
    @apply leading-tight font-semibold font-proxSemi; 

    @screen lg {
        font-size: calc(2 * theme('fontSize.base-heading-size'));
    }

    &.big {
       font-size: calc(2.6 * theme('fontSize.base-heading-size')); 
    } 

    &.device {
        @apply relative;

        &:before {
            content: "";
            @apply absolute bg-red w-20 h-1.5 left-0;
        }

        &.top {
            @apply pt-8;

            &:before { 
                @apply top-0;
            }
        }

        &.bottom {
            @apply pb-8;

            &:before { 
                @apply bottom-0;
            }
        }

        &.white {
             &:before {
                @apply bg-white;
             }
        }
    }
}

.h3, h3 {
    @apply leading-tight font-semibold font-proxSemi; 
    font-size: calc(1.3 * theme('fontSize.base-heading-size')); 

    @screen lg {  
        font-size: calc(1.4 * theme('fontSize.base-heading-size'));
        @apply font-bold font-proxBold; 
    
    }
}

.h4, h4 {
    @apply leading-tight text-lg font-bold font-proxBold;

    @screen lg {  
        @apply text-xl;
    }
}

.h5, h5 {
    @apply text-lg leading-tight font-proxSemi;

    @screen lg { 
        font-size: calc(1.2 * theme('fontSize.base-heading-size'));
    }
}

// .h6, h6 {
//  @apply text-base;
// }

button,
.button,
.wysiwyg .button,
input[type=submit] {
    transition: all 0.3s;
    @apply cursor-pointer inline-block;
    box-sizing: border-box;

    &:hover,
    &:active,
    &:focus {
        @apply cursor-pointer;

    }

    &.primary {
        @apply inline-flex text-base bg-red text-white px-6 py-3 text-center relative font-proxBold uppercase;

        @screen lg {
            @apply px-8 py-4;
        }

        &.alt {
            @apply bg-white text-red;
        }

        &:hover,
        &:active, 
        &:focus {
            @apply bg-offBlack;   
        }
    }

    &.secondary {
        @apply inline-flex text-base text-red text-center relative font-proxBold uppercase;

        span {
            @apply relative;

            &:before {
                content: "";
                @apply absolute bottom-0 w-full h-0.5 bg-red;
            }
        }

        &:hover,
        &:active, 
        &:focus {
            @apply text-offBlack; 

            span {
            @apply relative;

                &:before {
                    content: "";
                    @apply absolute bottom-0 w-full h-0.5 bg-offBlack;
                }
            }  
        }
    }


    &[disabled=disabled], 
    &:disabled {
        opacity: 0.3;
        cursor: default;
    }

    // &.link.disabled {
    //  cursor: default;
    //  &:hover {
    //      &:after {
    //          @apply opacity-0 w-full;
    //      }
    //  }
    // }

    &:focus {
        outline: none;
    }
}


.link {
    @apply relative inline-block;

    &:before {
        content: "";
        @apply h-px absolute bg-white bottom-0 left-0 w-full transition-all duration-300 opacity-0;
    }

    &:hover {
        &:before {
            @apply opacity-100;
        }
    }
}    


.wysiwyg-block { 

    h3,h4,h5 {
        
        @apply mb-2 mt-8 text-offBlack;

        @screen lg {
            @apply mb-4;
        }

        &:first-child {
            @apply mt-0;
        }
    }

    ul {
        @apply my-6 pl-9 font-proxReg;

        &:last-child {
            @apply mb-0;
        }

        li {
            @apply mb-2 relative text-offBlack;

            @screen lg { 
                @apply text-xl;
            }

            span {
                @apply inline-block;
                &:first-child {
                    @apply w-3/5;
                }
            }

            &:before {
                content: "";
                @apply absolute bg-offBlack rounded-full;
                width: 4px;
                height: 4px;
                top: 8px;
                left: -16px;

                @screen lg {  
                    top: 13px;
                }
            }
        }
    }

    ol {
        @apply font-proxReg;
        counter-reset: my-awesome-counter;

        li {
            @apply text-offBlack mb-2 px-4;
            counter-increment: my-awesome-counter;

            @screen lg { 
                @apply text-xl;
            }

            &:before {
                @apply pr-2 inline-block;
                content: counter(my-awesome-counter) ") ";

                @screen lg {  
                    width: 30px;
                }
            }
        }
    } 

    &.location-list {
        a {
            @apply text-red underline;

            &:hover {
                @apply text-offBlack;
            }
        }
    }      
}


