/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.form-input[type="text"],
.form-input[type="password"],
.form-input[type="number"],
.form-input[type="email"],
.form-select,
textarea.form-input {
	@apply font-proxReg border border-ltGrey border-solid text-offBlack py-2 px-4 text-lg bg-white;

	&::placeholder {
		@apply text-medGrey;
	}

	&:focus {
		@apply border-ltGrey outline-none shadow-none ring-0;
	}

	@screen xl {
		@apply text-xl;
	}

	&.ff-has-errors {
		span.label {
			@apply text-error;
		}

			@apply border-error;

	}
}

.ff-form-errors {
	@apply mb-10 text-error
}

label {
	@apply relative;

	span.label {
		@apply font-proxReg text-offBlack text-lg;

		@screen xl {
			@apply text-xl;
		}
	}

	ul.ff-errors {
		li {
			@apply font-proxReg text-error text-lg absolute right-0 top-0;

			@screen xl {
				@apply text-xl;
			}
		}

	}


}
