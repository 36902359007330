#content {
	
	.section-p {
		@apply py-8;

		@screen lg {
			@apply py-20;
		}

		// &:first-child {
		// 	@apply pt-0;
		// }

		// &:last-child {
		// 	@apply pb-0;
		// }
	}

	.section-m {
		
	}

	.section-p.bg-offWhite + .section-p.bg-offWhite {
		@apply pt-0;
	}

	.section-p.bg-white + .section-p.bg-white {
		@apply pt-0;
	}
	
}
