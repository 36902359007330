// Base
// Includes styles that hardly change and don't require compiling as often

// Tailwind imports
@tailwind base;
@tailwind components;

// Imports
// Include variables, base styles, grids, typography, components and other partials
/* purgecss start ignore */
@import "global/fonts";
@import "partials/forms";
@import "partials/pagination";
@import "global/typography";
@import "sections/sections";
/* purgecss end ignore */


// Base styles
html, body {
    //scroll-behavior: smooth;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a, a svg, .button, .link {
    @apply transition-all duration-300 ease-in-out;
}

.responsive-embed {
    padding-bottom: 56.25%;
    @apply relative h-0;
}

.responsive-embed iframe {
    @apply absolute top-0 left-0 w-full h-full;
}



[v-cloak] {
	display: none !important;
}
